/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react";
import { SubmitProvider } from "./src/context/submitContext";
export const wrapRootElement = ({ element }) => (
  <SubmitProvider>{element}</SubmitProvider>
);

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css";
import "./src/styles.scss";
import "@fontsource/montserrat";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800-italic.css";
