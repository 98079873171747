import React from "react";

const defaultState = {
  leadSent: false,
  setLeadSent: () => {},
};

export const SubmitContext = React.createContext(defaultState);

class SubmitProvider extends React.Component {
  state = {
    leadSent: false,
  };

  setLeadSent = () => {
    this.setState({ leadSent: true });
  };

  render() {
    const { children } = this.props;
    const { leadSent } = this.state;
    return (
      <SubmitContext.Provider
        value={{ leadSent, setLeadSent: this.setLeadSent }}
      >
        {children}
      </SubmitContext.Provider>
    );
  }
}

export default SubmitContext;
export { SubmitProvider };
